import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Link from 'next/link';

import {
  selectCategories,
  selectFeatures,
  selectTopPageSections,
} from 'lib/util/privateMarketplace';
import { toFooterSettingsShape } from 'lib/util/footerSettingsShape';
import { encodeCategoryNameForURI } from 'lib/util/encodeCategoryNameForURI';
import { ReduxState } from 'ducks';
import { SnsIcons } from 'components/Sns/SnsIcons';
import { useCustomFooterLinks } from 'hooks/useCustomFooterLinks';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useQueryString } from 'hooks/useQueryString';
import { useThemeParams } from 'hooks/useThemeParams';

import styles from './PMPFooterView.module.css';
import baseStyles from '../base.module.css';

const PMPFooterView = () => {
  const { i18n, t } = useTranslation();

  const settings = useSelector((state: ReduxState) =>
    toFooterSettingsShape(state.server.settings.all)
  );

  const { usesColorCustomization } = useThemeParams();

  const categories = useSelector(selectCategories);
  const features = useSelector(selectFeatures);
  const topPageSections = useSelector(selectTopPageSections);

  const customFooterLinks = useCustomFooterLinks();

  const showPopularProducts = topPageSections.includes('POPULAR_PRODUCTS');
  const showRecommendedProducts = topPageSections.includes('RECOMMENDED_PRODUCTS');
  const showCategory = topPageSections.includes('CATEGORY');
  const showFeature = topPageSections.includes('FEATURE');

  const queryString = useQueryString();

  return (
    <footer className={clsx(styles['footer'], usesColorCustomization && styles['custom'])}>
      <span className={baseStyles['color-footer-background']}></span>
      <div className={styles['footer__nav']}>
        {(showPopularProducts || showRecommendedProducts) && (
          <div className={styles['footer__nav__box']}>
            <p className={styles['footer__nav__box__ttl']}>{t('Ranking')}</p>
            <ul className={styles['footer__nav__box__list']}>
              {showPopularProducts && (
                <li className={styles['footer__nav__box__list__item']}>
                  <Link href={appendQueryString('/top/popular', queryString)}>
                    {t('Popularity Ranking')}
                  </Link>
                </li>
              )}
              {showRecommendedProducts && (
                <li className={styles['footer__nav__box__list__item']}>
                  <Link href={appendQueryString('/top/recommended', queryString)}>
                    {t('Recommended Products')}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
        {showCategory && (
          <div className={styles['footer__nav__box']}>
            <p className={styles['footer__nav__box__ttl']}>{t('Find by Category')}</p>
            <ul className={styles['footer__nav__box__list']}>
              {categories.map((category, idx) => (
                <li className={styles['footer__nav__box__list__item']} key={idx}>
                  <Link
                    href={appendQueryString(
                      `/top/${encodeCategoryNameForURI(category.name)}`,
                      queryString
                    )}
                  >
                    {category.display_name || category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        {showFeature && (
          <div className={styles['footer__nav__box']}>
            <p className={styles['footer__nav__box__ttl']}>{t('Featured')}</p>
            <ul className={styles['footer__nav__box__list']}>
              {features.map((feature, idx) => (
                <li className={styles['footer__nav__box__list__item']} key={idx}>
                  <Link
                    href={appendQueryString(
                      `/top/${encodeCategoryNameForURI(feature.name)}`,
                      queryString
                    )}
                  >
                    {feature.display_name || feature.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        {customFooterLinks.length > 0 && (
          <div className={clsx(styles['footer__nav__box'], styles['footer__mobile__top__padding'])}>
            <ul className={styles['footer__nav__box__list']}>
              {customFooterLinks.map((footerLink, idx) => (
                <li className={styles['footer__nav__box__list__item']} key={idx}>
                  {footerLink.custom_page_path ? (
                    <Link href={appendQueryString(footerLink.custom_page_path, queryString)}>
                      {footerLink.text}
                    </Link>
                  ) : (
                    <a href={footerLink.url} target="_blank" rel="noopener noreferrer">
                      {footerLink.text}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className={clsx(styles['footer__inner'])}>
        <div className={styles['footer__info']}>
          <ul>
            <li>
              <picture>
                <img src="/static/images/ic_phone.svg" alt="" />
              </picture>
              {settings.phone}
            </li>
            <li>
              <picture>
                <img src="/static/images/ic_mail.svg" alt="" />
              </picture>
              {settings.email}
            </li>
          </ul>
          <ul>
            <li>
              <picture>
                <img src="/static/images/ic_location.svg" alt="" />
              </picture>
              {settings.address}
            </li>
            <li>
              <picture>
                <img src="/static/images/ic_time.svg" alt="" />
              </picture>
              {t('Hours: {{operatingHoursDescription}}', {
                operatingHoursDescription: settings.operatingHoursDescription,
              })}
            </li>
          </ul>
          <SnsIcons snsIds={settings?.snsIds} />
        </div>
        <div className={styles['footer__menu']}>
          {i18n.language === 'ja-JP' && settings.showJapaneseTerms && (
            <ul>
              <li>
                <a href="https://app.ntmg.com/ja/terms" target="_blank" rel="noopener noreferrer">
                  特定商取引法
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
      <p className={styles['footer__copy']}>© 2022 NutmegLabs Inc.</p>
    </footer>
  );
};

export default PMPFooterView;
